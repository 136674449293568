.custom-modal-container{
    display: flex;
    flex-direction: column;
}

.custom-modal-header-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.custom-header-title{
    font-weight: bold;
    font-size: 20px;
}

.modal-pdf-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.full-pdf-dimension{
    width: 640px;
    height: 480px;
}

.modal-label-container{
    display: flex;
    width: 640px;
    flex-direction: row;
    background-color: grey;
}

.modal-label{
    flex:11;
    background-color: transparent;
    color: white;
    border: none;
    font-size: 20px;
}

.modal-save-button{
    flex: 1;
    height: 100%;
    background-color: grey;
    border: none;
    margin: 0px;
}

.modal-close-button{
    color: grey;
    background-color: transparent;
    border: none;
}

.modal-close-icon{
    color: grey;
    font-size: 25px;
}

.modal-save-icon{
    color: white;
}
