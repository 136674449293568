@import "../../scss/variables";

.toast-notification {
  align-items: center;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  overflow: hidden;
  padding: 10px;
  position: fixed;
  right: 12px;
  top: 54px;
  width: 268px;
  z-index: 1000;
}

.toast-contents {
  border-left: 1px solid #eee;
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  padding-left: 10px;

  span {
    font-size: 0.875rem;
    line-height: 1rem;
  }

  .toast-title {
    font-weight: 700;
    padding-bottom: 6px;
  }
}

.toast-tick-icon,
.toast-close-icon {
  flex-shrink: 0;
}

.toast-close-icon {
  align-self: flex-start;
  cursor: pointer;
}

.toast-decoration {
  bottom: 0;
  display: flex;
  height: 3px;
  left: 0;
  position: absolute;
  right: 0;

  span:first-child {
    background-color: #27ae60;
    flex: 0 0 160px;
  }

  span:last-child {
    background-color: rgba(39, 174, 96, 0.25);
    flex-grow: 1;
  }
}

.toast-decoration-warning {
  bottom: 0;
  display: flex;
  height: 3px;
  left: 0;
  position: absolute;
  right: 0;

  span:first-child {
    background-color: #f2c94c;
    flex: 0 0 160px;
  }

  span:last-child {
    background-color: #f2c94c;
    opacity: 0.25;
    flex-grow: 1;
  }
}
