.default-all-color {
  color: #000000; }

.open-color {
  color: #000000; }

.pending-color {
  color: #ff8256; }

.rejected-color {
  color: #d0021b; }

.completed-color {
  color: #417505; }

.pending-activation-color {
  color: #ff8256; }

.pending-declaration-color {
  color: #ff8256; }

.aborted-color {
  color: #717171; }

/* For printing content UI purpose */
@media print {
  [class*="col-sm-"] {
    float: left; }
  [class*="col-xs-"] {
    float: left; }
  .col-sm-12, .col-xs-12 {
    width: 100% !important; }
  .col-sm-11, .col-xs-11 {
    width: 91.66666667% !important; }
  .col-sm-10, .col-xs-10 {
    width: 83.33333333% !important; }
  .col-sm-9, .col-xs-9 {
    width: 75% !important; }
  .col-sm-8, .col-xs-8 {
    width: 66.66666667% !important; }
  .col-sm-7, .col-xs-7 {
    width: 58.33333333% !important; }
  .col-sm-6, .col-xs-6 {
    width: 50% !important; }
  .col-sm-5, .col-xs-5 {
    width: 41.66666667% !important; }
  .col-sm-4, .col-xs-4 {
    width: 33.33333333% !important; }
  .col-sm-3, .col-xs-3 {
    width: 25% !important; }
  .col-sm-2, .col-xs-2 {
    width: 16.66666667% !important; }
  .col-sm-1, .col-xs-1 {
    width: 8.33333333% !important; }
  .col-sm-1,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12,
  .col-xs-1,
  .col-xs-2,
  .col-xs-3,
  .col-xs-4,
  .col-xs-5,
  .col-xs-6,
  .col-xs-7,
  .col-xs-8,
  .col-xs-9,
  .col-xs-10,
  .col-xs-11,
  .col-xs-12 {
    float: left !important; }
  html, body {
    height: auto; }
  body {
    min-width: 768px;
    font-size: 10px; }
  .container {
    width: auto;
    min-width: 750px; }
  a[href]:after {
    content: none; }
  .readOnlyTextBox-transparent {
    height: 50px; } }

.section {
  flex: 1; }
  .section .filterBar {
    background: linear-gradient(-90deg, #4485a9 0%, #013b64 81.77%);
    color: #ffffff;
    padding: 16px 32px;
    padding-right: 72px; }
  .section h1 {
    font-size: 1.3125rem;
    font-weight: 400;
    line-height: 1.5625rem;
    margin: 0; }
  .section .filters {
    display: flex;
    margin-top: 16px; }
    .section .filters > div {
      flex: 1; }
  .section .filtersLeft {
    display: flex; }
    .section .filtersLeft form {
      flex: 1;
      margin-left: 8px;
      margin-top: 26px; }
    .section .filtersLeft .position-relative.row.form-group {
      margin: 0; }
  .section .dropdown.invalid .dropdownToggle {
    border-color: red; }
  .section .dropdown.invalid ~ :global(.invalid-feedback) {
    display: block; }
  .section .dropdownToggle {
    font-size: 0.875rem;
    height: 38px;
    line-height: 1rem;
    padding: 10px; }
  .section .secondaryFilters {
    display: flex;
    justify-content: flex-end;
    padding: 16px 32px;
    padding-right: 72px; }
  .section .filterByStatus {
    align-items: center;
    display: flex; }
    .section .filterByStatus label {
      margin: 0;
      margin-right: 10px; }
  .section .exportCsv {
    margin-left: 20px; }
    .section .exportCsv button {
      height: 38px; }
  .section .customerList {
    padding: 16px 32px;
    padding-right: 72px; }
    .section .customerList .nav {
      display: flex;
      justify-content: flex-end;
      margin: 32px 0; }
    .section .customerList .pagination {
      margin: 0; }
  .section .tableContainer {
    border: 1px solid #c9ced3;
    border-radius: 4px;
    box-shadow: 0px 5px 10px rgba(170, 170, 170, 0.1);
    margin-bottom: 32px;
    overflow: auto; }
  .section .table {
    width: 100%; }
    .section .table th,
    .section .table td {
      padding: 16px; }
    .section .table th {
      background-color: #ffffff; }
    .section .table td {
      border-top: 1px solid #c9ced3; }
    .section .table tbody > tr:nth-child(even) > td {
      background-color: #ffffff; }
    .section .table tbody > tr:first-child > td {
      border-top-width: 2px; }
  .section a {
    color: #002559; }
  .section .statusPill {
    border-radius: 4px;
    font-size: 0.75rem;
    font-weight: 500;
    line-height: 1.25rem;
    padding: 2px 7px; }
    .section .statusPill.submitted {
      background-color: #002559;
      color: #ffffff; }
    .section .statusPill.approved {
      background-color: #27ae60;
      color: #ffffff; }
    .section .statusPill.rejected {
      background-color: #d71920;
      color: #ffffff; }
    .section .statusPill.processing {
      background-color: #f2c94c;
      color: #333333; }
    .section .statusPill.activated {
      background-color: #f16932;
      color: #ffffff; }
  .section .primaryOutlined {
    background-color: #ffffff;
    border-color: #002559;
    color: #002559;
    transition-duration: 0.1s; }
  .section .pageItem.disabled {
    cursor: no-drop; }
    .section .pageItem.disabled button {
      color: #ccc !important; }
  .section .backButton {
    align-items: center;
    cursor: pointer;
    display: inline-flex;
    margin-bottom: 8px;
    margin-left: -21px;
    margin-top: 0; }
    .section .backButton:hover {
      text-decoration: underline; }
    .section .backButton span {
      font-size: 1.125rem;
      font-weight: 700;
      line-height: 1.25rem;
      margin-left: 12px; }
  .section .breadcrumbs {
    color: #888;
    font-size: 0.875rem;
    line-height: 1.25rem;
    margin-bottom: 32px; }
    .section .breadcrumbs .highlighted {
      color: #63a5da;
      font-weight: 500; }
  .section .userDetails {
    flex: 1;
    padding: 52px;
    padding-top: 36px; }
  .section .userDetailsForm .userDetailsFormTitle {
    color: #d71920;
    font-weight: 700; }
  .section .userDetailsForm .userDetailsFormTitle1 {
    color: #002659;
    font-weight: 700; }
  .section .userDetailsForm .userDetailsFormDetails {
    display: flex;
    flex-direction: column;
    margin-top: 11px;
    width: 50%; }
  .section .userDetailsForm .inputField {
    margin-bottom: 16px; }
    .section .userDetailsForm .inputField .caption {
      color: #666666;
      font-weight: 400; }
  .section .userDetailsForm .unEditableField {
    font-weight: 700;
    height: 38px;
    display: flex;
    align-items: center; }
  .section .userDetailsForm .userDetailsFormAction {
    display: flex;
    justify-content: flex-end;
    align-items: center; }
  .section .userDetailsForm .userDetailsDeleteButton {
    margin-right: 24px;
    padding: 0;
    background: none;
    border: none; }
  .section .userDetailsForm .userDetailsSubmitButton {
    background-color: #002559;
    color: #ffffff;
    width: 180px; }
  .section .backButton {
    align-items: center;
    cursor: pointer;
    display: inline-flex;
    margin-bottom: 8px;
    margin-left: -21px;
    margin-top: 0; }
    .section .backButton:hover {
      text-decoration: underline; }
    .section .backButton span {
      font-size: 1.125rem;
      font-weight: 700;
      line-height: 1.25rem;
      margin-left: 12px; }
  .section .breadcrumbs {
    color: #888;
    font-size: 0.875rem;
    line-height: 1.25rem;
    margin-bottom: 25px; }
    .section .breadcrumbs .highlighted {
      color: #63a5da;
      font-weight: 500; }

.modalHeader {
  background-color: #f4f4f4;
  padding: 1.25rem; }
  .modalHeader button {
    font-size: 1.75rem;
    font-weight: 500;
    opacity: 0.75; }

.modalActions {
  display: flex;
  justify-content: flex-end;
  padding: 0px 4px 0px; }

.modalButtonSecondary {
  background-color: #fff;
  border-color: black;
  width: 20%; }

.modalButtonPrimary {
  margin-left: 16px;
  width: 20%;
  background-color: #002559;
  color: #fff; }

.alink {
  cursor: pointer;
  text-decoration: underline;
  color: #002559 !important; }
  .alink:hover {
    background: rgba(38, 111, 182, 0.5) !important; }
  .alink.active {
    background: rgba(38, 111, 182, 0.5) !important; }

.toastNotification {
  align-items: center;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  overflow: hidden;
  padding: 10px;
  position: fixed;
  right: 12px;
  top: 54px;
  width: 268px;
  z-index: 1000; }

.toastContents {
  border-left: 1px solid #eee;
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  padding-left: 10px; }
  .toastContents span {
    font-size: 0.875rem;
    line-height: 1rem; }
  .toastContents .toastTitle {
    font-weight: 700; }

.toastTickIcon,
.toastCloseIcon {
  flex-shrink: 0; }

.toastCloseIcon {
  align-self: flex-start; }

.toastDecoration {
  bottom: 0;
  display: flex;
  height: 3px;
  left: 0;
  position: absolute;
  right: 0; }
  .toastDecoration span:first-child {
    background-color: #27ae60;
    flex: 0 0 160px; }
  .toastDecoration span:last-child {
    background-color: rgba(39, 174, 96, 0.25);
    flex-grow: 1; }

.toastDecorationWarning {
  bottom: 0;
  display: flex;
  height: 3px;
  left: 0;
  position: absolute;
  right: 0; }
  .toastDecorationWarning span:first-child {
    background-color: #f2c94c;
    flex: 0 0 160px; }
  .toastDecorationWarning span:last-child {
    background-color: #f2c94c;
    opacity: 0.25;
    flex-grow: 1; }

.alert {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  padding: 6px;
  margin-bottom: 12px;
  border-left: 3px solid #fff;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.1);
  align-items: center; }
  .alert.warn {
    border-color: #F2C94C; }

.alertIcon {
  padding: 12px 12px 12px 6px;
  border-right: 1px solid #eee; }

.alertMsg {
  padding: 12px;
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  color: #333; }
