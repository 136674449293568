@import "../../scss/variables";

.tabBar {
    margin-top: 32px;

    .nav-item+.nav-item {
        margin-left: 40px;
    }
}

.tabName {
    font-size: 0.875rem;
    font-weight: 400;
}

.tab-header.nav-link {
    border: none;
    border-bottom: 3px solid transparent;
    color: #ADABAD;
    padding: 10px 0;

    span {
        color: #ADABAD;
    }

    &.active {
        background-color: transparent;
        border-bottom: 3px solid $primaryColor;

        span {
            color: $primaryColor;
            font-weight: 700;
        }
    }
}