@import "../../scss/variables";

.squareRadioButtonContainer {
    flex: 1;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px,
}

.circleRadioButtonContainer {
    flex: 1;
    justify-content: center;
    align-items: center;
    margin-left: 15px;
}

.radioButtonSquare {
    flex-direction: row;
    margin-left: 10px;
    margin-right: 10px;
    align-items: center;
    justify-content: center;
    position: relative;
    background-color: $primaryColor;
}

.radioButtonActiveHolder {
    flex: 1;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 100%;
    background-color: $primaryColor;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 5px;
    padding-right: 5px;
}

.radioButtonInactiveHolder {
    flex: 1;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 100%;
    background-color: grey;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 5px;
    padding-right: 5px;
}

.radioButtonCircle {
    flex-direction: row;
    margin-left: 10px;
    margin-right: 10px;
    align-items: flex-start;
    justify-content: flex-start,
}

.radioButtonHolder {
    border-radius: 50px;
    border-width: 2px;
    justify-content: center;
    align-items: center;
    margin-top: 3px;
    margin-left: 15px;
    margin-right: 15px;
    height: 20px;
    width: 20px;
}

.radioIcon {
    border-radius: 50px;
    justify-content: center;
    align-items: center;
    height: 15px;
    width: 1px;
    background-color: yellow;
}