@import "../../scss/variables";

.help-guide,
.help-backdrop {
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: $zIndex-sky;
}

.help-guide {
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 120px;
}

.help-modal {
    background-color: $whiteColor;
    border-radius: 8px;
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
    width: 100%;

    .modal-header {
        align-items: center;
        background-color: #f4f4f4;
        border-bottom: 1px solid #c9ced3;
        display: flex;
        height: 100px;
        justify-content: space-between;
        padding: 20px;
    }

    .modal-title {
        font-size: 1.3125rem;
        line-height: 1.5625rem;
        letter-spacing: 0.15px;
    }

    .cancel-icon {
        border-radius: 50%;
        cursor: pointer;
        margin-right: -7px;
        padding: 14px;
        transition: background-color 0.05s ease;

        &:hover {
            background-color: transparentize($color: $primaryColor, $amount: 0.9);
        }
    }

    .modal-body {
        display: flex;
        padding: 0;
    }

    .left-nav {
        background-color: #fafafa;
        flex: 0 0 266px;

        ul {
            list-style-type: none;
            margin: 0;
            padding: 0;
            user-select: none;
        }

        li {
            border-bottom: 1px solid #ccc;
            cursor: pointer;
            padding: 20px;
            transition: background-color 0.05s ease;

            &:hover {
                background-color: transparentize($color: $primaryColor, $amount: 0.95);
            }

            &:active {
                background-color: transparentize($color: $primaryColor, $amount: 0.9);
            }

            &.active {
                font-weight: 700;
                position: relative;

                &::after {
                    border-color: $primaryColor;
                    border-style: solid;
                    border-width: 0 0 2px 2px;
                    content: "";
                    display: block;
                    height: 8px;
                    position: absolute;
                    right: 20px;
                    top: 50%;
                    transform: rotateZ(315deg) translate(50%, -50%);
                    width: 12px;
                }
            }
        }
    }

    .content {
        flex: 1;
        overflow-x: hidden;
        overflow-y: auto;
    }
}

.help-backdrop {
    background-color: rgba(0, 0, 0, 0.5);
    z-index: -1;
}