@import "../../scss/variables";

.filterRadio {
    .wrap {
        display: flex;
        align-items: center;
        border-right: 1px solid $lightGreyColor;
        margin-bottom: 5px;
    }

    .btn-secondary {
        background-color: $whiteColor;
        box-shadow: none;
        border-color: $primaryColor;
        font-size: 0.875rem;
        line-height: 1rem;
        padding: 10px;

        +.btn-secondary {
            margin-left: 10px;
        }

        &:not(:disabled):not(.disabled) {

            &:active,
            &.active {
                background-color: $primaryColor;
                border-color: $primaryColor;
                color: $whiteColor;

                &:focus {
                    box-shadow: none;
                }
            }
        }
    }

}