// Here you can add other styles

////==== General ====////
.container {
  padding-left: 16px;
  padding-right: 16px;
  &.flush-max-width-- {
    max-width: none;
  }
}

@include screen-md-up {
  .container {
    padding-left: 32px;
    padding-right: 32px;
  }
}

////==== SideNav ====////
.sidebar{
  background: transparent;
}

.custom-nav {
  flex: auto !important;
  background: linear-gradient(360deg, #485173 0%, #24283E 100%) !important;
  height: calc(100vh - 45px) !important;
  .scrollbar-container {
    &.sidebar-nav {
      ul.nav {
        width: auto;
      }
    }
  }
  .nav-dropdown.open{
    background: transparent;
  }

  .nav-link {
    display: block;
    color: $white !important;
    text-decoration: none;
    background: transparent !important;
    font-size: 12px; 
    
    .nav-icon{
        color: $white;
    }

    &:hover{
        background: rgba(38, 111, 182, 0.5) !important;
    }

    &.active {
        background: rgba(38, 111, 182, 0.5) !important;
        .nav-icon{
            color: $white;
        }
    }
  }
}

.main-container {
  background-color: #fafafa;
  min-height: 80vh;
}
