@import "../../scss/variables";
/* Center the loader */
.loading {
  border: 10px solid transparent;
 border-radius: 50%;
  border-top: 10px solid $primaryColor;
  width: 10em;
  height: 10em;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
  position: fixed;
  z-index: 9999;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.loading-text-container{
  width: 10em;
  height: 10em;
  position: fixed;
  z-index: 9999;
  overflow: show;
  left:0;
  bottom: 0;
  right: 0;
  top:0;
  color: $primaryColor;
  font-size:2em;
  text-align:center;
  margin:auto;
  vertical-align:middle;
}

.loading-text{
	  position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 9999;
}


.overlay {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background: radial-gradient(rgba(20, 20, 20,.5),rgba(0, 0, 0, .5));
  background: -webkit-radial-gradient(rgba(20, 20, 20,.5), rgba(0, 0, 0,.8));
}

@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
