.default-all-color {
  color: #000000; }

.open-color {
  color: #000000; }

.pending-color {
  color: #ff8256; }

.rejected-color {
  color: #d0021b; }

.completed-color {
  color: #417505; }

.pending-activation-color {
  color: #ff8256; }

.pending-declaration-color {
  color: #ff8256; }

.aborted-color {
  color: #717171; }

/* For printing content UI purpose */
@media print {
  [class*="col-sm-"] {
    float: left; }
  [class*="col-xs-"] {
    float: left; }
  .col-sm-12, .col-xs-12 {
    width: 100% !important; }
  .col-sm-11, .col-xs-11 {
    width: 91.66666667% !important; }
  .col-sm-10, .col-xs-10 {
    width: 83.33333333% !important; }
  .col-sm-9, .col-xs-9 {
    width: 75% !important; }
  .col-sm-8, .col-xs-8 {
    width: 66.66666667% !important; }
  .col-sm-7, .col-xs-7 {
    width: 58.33333333% !important; }
  .col-sm-6, .col-xs-6 {
    width: 50% !important; }
  .col-sm-5, .col-xs-5 {
    width: 41.66666667% !important; }
  .col-sm-4, .col-xs-4 {
    width: 33.33333333% !important; }
  .col-sm-3, .col-xs-3 {
    width: 25% !important; }
  .col-sm-2, .col-xs-2 {
    width: 16.66666667% !important; }
  .col-sm-1, .col-xs-1 {
    width: 8.33333333% !important; }
  .col-sm-1,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12,
  .col-xs-1,
  .col-xs-2,
  .col-xs-3,
  .col-xs-4,
  .col-xs-5,
  .col-xs-6,
  .col-xs-7,
  .col-xs-8,
  .col-xs-9,
  .col-xs-10,
  .col-xs-11,
  .col-xs-12 {
    float: left !important; }
  html, body {
    height: auto; }
  body {
    min-width: 768px;
    font-size: 10px; }
  .container {
    width: auto;
    min-width: 750px; }
  a[href]:after {
    content: none; }
  .readOnlyTextBox-transparent {
    height: 50px; } }

.sdkPreview {
  position: relative;
  padding: 0 18px; }

.main {
  position: relative;
  z-index: 1;
  width: 375px;
  margin: auto;
  padding: 16px 0;
  background-color: #fafafa; }

.frame {
  position: absolute;
  z-index: 2;
  left: -18px;
  top: 0;
  width: 410px; }
  .frame > svg {
    width: 410px; }

.wrapper {
  position: relative;
  width: 375px;
  height: 810px; }

.container {
  margin: 0 24px; }

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 48px 16px 16px;
  background-color: #002659;
  border-bottom: 4px solid #D71920; }

.headerTitle {
  color: #fff;
  font-size: 17px; }

.headerBack {
  fill: #fff;
  width: 24px;
  height: 24px; }

.headerClose {
  fill: #fff;
  width: 24px;
  height: 24px; }

.stepSection {
  padding: 10px 24px;
  background-color: #F2F8FF;
  box-shadow: 0px 2px 5px 0 rgba(204, 204, 204, 0.8);
  display: flex;
  justify-content: space-between;
  align-items: center; }

.stepInfo {
  position: relative; }

.stepInfoTitle {
  font-size: 16px;
  font-weight: 700;
  line-height: 1.125;
  color: #333;
  text-align: right;
  padding-bottom: 3px; }

.stepInfoNext {
  font-size: 12px;
  font-weight: 400;
  line-height: 1.125;
  color: #333;
  text-align: right; }

.progressBar {
  position: relative;
  overflow: hidden;
  width: 50px;
  height: 50px;
  border-radius: 25px;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  background-color: #D8D8D8; }

.progressBarInner {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 42px;
  height: 42px;
  border-radius: 21px;
  background-color: #F2F8FF;
  z-index: 2; }

.progressBarInnerStep {
  color: #666;
  line-height: 1.2;
  font-size: 8px; }

.progressBarInnerCounter {
  color: #333;
  font-size: 10px;
  line-height: 1.1;
  font-weight: 700; }

.progressBarLeftPanel {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding-right: 50%;
  z-index: 1;
  transform: rotate(298deg); }

.progressBarRightPanel {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  padding-left: 50%;
  z-index: 1; }

.progressBarPanelInner {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #002659; }

.logoSection {
  padding: 16px 0; }

.logoWrapper {
  color: #666;
  font-size: 10px;
  line-height: 1.15; }
  .logoWrapper > img {
    display: inline;
    vertical-align: middle;
    width: 100%;
    max-width: 64px;
    margin-left: 5px; }

.accorSection {
  position: relative; }

.accorHeader {
  padding: 12px 32px;
  background-color: #EAECF3;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  min-height: 22px; }

.accorHeaderTitle {
  position: relative;
  color: #333;
  font-size: 15px;
  font-weight: 700;
  line-height: 1.15;
  padding-left: 34px;
  padding-top: 4px; }

.accorHeaderTitleIcon {
  position: absolute;
  left: 0;
  top: 0; }
  .accorHeaderTitleIcon > svg {
    width: 24px; }

.accorHeaderArrow {
  width: 24px;
  fill: #6B6C6F; }
  .accorHeaderArrow.rotated {
    transform: rotate(180deg); }

.accorBody {
  position: relative;
  padding: 24px 62px 0 32px; }

.accorBodyClose {
  position: absolute;
  top: 24px;
  right: 32px; }
  .accorBodyClose > svg {
    width: 18px;
    height: 18px; }

.inputBlock {
  padding-bottom: 24px; }

.inputLabelBold {
  color: #333;
  font-size: 14px;
  font-weight: 700;
  padding-bottom: 16px;
  line-height: 1; }

.inputLabelTooltip {
  display: inline;
  padding-left: 8px; }

.checkboxBlock {
  display: flex;
  flex-direction: row;
  align-items: center; }

.checkbox {
  padding: 9px 9px 9px 0;
  margin-left: -3px; }
  .checkbox > svg {
    width: 24px;
    height: 24px; }

.checkboxLabel {
  font-size: 14px; }

.switchBlock {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%; }

.switchButton {
  padding: 12px 16px;
  border: 1px solid #BDBDBD;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.75;
  text-align: center;
  height: 48px;
  width: calc(50% - 8px);
  background-color: #fff; }

.buttonBlock {
  width: 100%;
  padding: 24px; }

.priButton,
.disabledButton {
  padding: 12px 16px;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.75;
  color: #333;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  text-align: center;
  border-radius: 8px;
  background-color: #002659; }

.disabledButton {
  background-color: #e0e0e0;
  color: #a6a6a6;
  box-shadow: none; }

.submitSection {
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 8px;
  padding: 0 24px; }
