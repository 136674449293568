
@import "../../scss/_variables";

.page-item.active .page-link{
    z-index:1;
    color: white !important;
    background-color: $primaryColor !important;
    border-color:$primaryColor !important;
}

.page-link{
    color: $primaryColor !important;
    min-width: 44px;
    text-align: center;
}

.page-item.disabled .page-link {
    background-color: #fafafa;
    color: #999 !important;
}

.page-item.disabled{
    cursor: no-drop;

        button {
            color: #ccc !important;
        }
}