@import "../../scss/variables";

.section{
    border-bottom: 5px solid;
    border-bottom-color: $darkBlue;
    padding: 0;
    margin: 0 !important;
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 60px;
    flex: 0 0 60px;
    background-color:$skyBlue;
}

.footerNote{
    font-size: 16px;
    font-weight: 400;
    line-height: 1.15;
}