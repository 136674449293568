.default-all-color {
  color: #000000; }

.open-color {
  color: #000000; }

.pending-color {
  color: #ff8256; }

.rejected-color {
  color: #d0021b; }

.completed-color {
  color: #417505; }

.pending-activation-color {
  color: #ff8256; }

.pending-declaration-color {
  color: #ff8256; }

.aborted-color {
  color: #717171; }

/* For printing content UI purpose */
@media print {
  [class*="col-sm-"] {
    float: left; }
  [class*="col-xs-"] {
    float: left; }
  .col-sm-12, .col-xs-12 {
    width: 100% !important; }
  .col-sm-11, .col-xs-11 {
    width: 91.66666667% !important; }
  .col-sm-10, .col-xs-10 {
    width: 83.33333333% !important; }
  .col-sm-9, .col-xs-9 {
    width: 75% !important; }
  .col-sm-8, .col-xs-8 {
    width: 66.66666667% !important; }
  .col-sm-7, .col-xs-7 {
    width: 58.33333333% !important; }
  .col-sm-6, .col-xs-6 {
    width: 50% !important; }
  .col-sm-5, .col-xs-5 {
    width: 41.66666667% !important; }
  .col-sm-4, .col-xs-4 {
    width: 33.33333333% !important; }
  .col-sm-3, .col-xs-3 {
    width: 25% !important; }
  .col-sm-2, .col-xs-2 {
    width: 16.66666667% !important; }
  .col-sm-1, .col-xs-1 {
    width: 8.33333333% !important; }
  .col-sm-1,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12,
  .col-xs-1,
  .col-xs-2,
  .col-xs-3,
  .col-xs-4,
  .col-xs-5,
  .col-xs-6,
  .col-xs-7,
  .col-xs-8,
  .col-xs-9,
  .col-xs-10,
  .col-xs-11,
  .col-xs-12 {
    float: left !important; }
  html, body {
    height: auto; }
  body {
    min-width: 768px;
    font-size: 10px; }
  .container {
    width: auto;
    min-width: 750px; }
  a[href]:after {
    content: none; }
  .readOnlyTextBox-transparent {
    height: 50px; } }

.main {
  flex: 1;
  padding: 52px;
  padding-top: 36px; }

.formWrapper {
  padding-top: 8px; }

.formSection {
  width: 100%;
  margin-bottom: 58px; }

.formSectionTitle {
  color: #757575;
  font-size: 16px;
  font-weight: 700;
  line-height: 1.25;
  color: #002559;
  padding: 0 8px;
  margin-bottom: 16px;
  border-left: 2px solid #d71920; }

.formStatusBar {
  font-size: 12px;
  font-style: italic;
  line-height: 14px;
  color: #666;
  padding-bottom: 24px; }

.formInputBlock {
  margin-bottom: 24px; }

.formInputLabel {
  padding-bottom: 8px; }
  .formInputLabel label {
    margin-bottom: 0; }

.formInputTooltipWrapper {
  display: inline;
  margin-left: 12px;
  padding-right: 14px; }

.formInputTooltip > :global(.tooltip-inner) {
  text-align: left;
  line-height: 20px;
  font-size: 14px;
  padding: 16px;
  max-width: 300px;
  border-radius: 4px;
  background-color: #fff;
  color: #1A1A1A;
  border: 1px solid #A7AFB2; }

.formInputTooltip > :global(.arrow) {
  left: 1px; }
  .formInputTooltip > :global(.arrow)::before {
    border-width: 8px 8px 8px 0;
    border-right-color: #888; }
  .formInputTooltip > :global(.arrow)::after {
    position: absolute;
    content: "";
    border-color: transparent;
    border-style: solid;
    right: 0;
    top: 1px;
    border-width: 7px 7px 7px 0;
    border-right-color: #fff; }

.formInputField {
  position: relative; }

.radioColorCards {
  position: relative;
  display: flex;
  flex-direction: row; }

.radioColorCard {
  position: relative;
  width: 175px;
  height: 68px;
  background-color: #fff;
  border-radius: 8px;
  margin-right: 24px;
  overflow: hidden;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25); }
  .radioColorCard.disabled {
    cursor: not-allowed; }

.colorCardHex {
  position: absolute;
  width: 100%;
  bottom: 6px;
  left: 0;
  padding-left: 8px;
  font-size: 14px;
  font-weight: 400;
  color: #fff;
  line-height: 1;
  text-transform: uppercase; }

.radioColorPicker {
  position: absolute;
  z-index: 3;
  left: 199px;
  box-shadow: 3px 3px 10px 2px rgba(0, 0, 0, 0.2);
  border-radius: 10px; }

.imgPreviewWrapper {
  position: relative;
  width: 100%;
  max-width: 220px;
  padding: 0px 32px 16px 0px; }

.imgPreviewClose {
  position: absolute;
  right: 4px;
  top: 0;
  width: 24px;
  height: 24px;
  border-radius: 100%;
  cursor: pointer;
  padding: 0;
  border: none;
  background-color: transparent;
  opacity: 1; }
  .imgPreviewClose:hover {
    opacity: .8;
    background-color: transparent; }
  .imgPreviewClose:global(.disabled) {
    cursor: not-allowed;
    background-color: transparent; }

.imgPreviewVisual > img {
  width: 100%;
  max-width: 170px; }

.imgPreviewInfo {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  font-size: 14px;
  padding-top: 8px;
  color: #798592; }

.previewInfoName {
  font-weight: 500;
  color: #333;
  width: 70%;
  word-break: break-all; }

.previewInfoSize {
  width: 30%;
  text-align: right; }

.previewInfoDimensions {
  width: 100%; }
  .previewInfoDimensions > span {
    font-size: 12px; }

.sdkCustInputFieldRemark {
  display: block;
  width: 100%;
  margin-top: 10px;
  font-size: 14px;
  font-style: italic;
  color: #666; }

.sdkCustInputFieldError {
  display: block;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #d0021b; }

.sdkFormAction {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 32px 24px; }

@media (min-width: 1200px) {
  .sdkFormAction {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: -47px;
    padding: 0; } }

.sdkFormButton,
.sdkResetButton {
  background-color: #002559;
  color: #ffffff;
  width: 180px;
  padding: 12px; }

.sdkResetButton {
  background-color: #fff;
  border: 1px solid #000000;
  color: #333;
  margin-right: 20px; }

.sdkModal .modalHeader {
  background-color: #f4f4f4;
  padding: 1.25rem; }
  .sdkModal .modalHeader button {
    font-size: 1.75rem;
    font-weight: 500;
    opacity: 0.75; }

.sdkModal .modalBody {
  padding-bottom: 48px;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px; }

.sdkModal .modal-actions {
  display: flex;
  justify-content: flex-end;
  padding: 46px 4px 0px; }

.sdkModal .modalButtonSecondary {
  background-color: #fff;
  border-color: black;
  width: 20%; }

.sdkModal .modalButtonPrimary {
  margin-left: 16px;
  width: 20%;
  background-color: #002559;
  color: #fff; }

.sdkModal .modalActions {
  display: flex;
  justify-content: flex-end;
  padding: 0px 4px 0px; }
