// Variable overrides
$primaryColor: #002559;
$whiteColor: #ffffff;
$secondaryColor: #d71920;
$lightGreyColor: #ccc;
$greyWhiteColor: #f5f5f5;
$darkGreyColor:#dedede;
$skyBlue:#e8effa;
$dbosGrey:rgba(0, 0, 0, 0.54);

//-- Primary colors --//
$darkBlue:  #002559;
$lightBlue: #4A90E2;

//-- common colors --//
$grey-100: #fafafa;
$grey-200: #e6e6e6;
$grey-300: #D9D9D9;
$grey-400: #cccccc;
$grey-500: #999999;
$grey-600: #8c8c8c;
$grey-700: #525252;
$grey-800: #494949;
$grey-900: #333333;
$black:    #000000;
$white:    #ffffff;

// Status Color
$OPEN: #000000;
$PENDING: #ff8256;
$COMPLETED: #417505;
$REJECTED: #d0021b;
$PENDING_ACTIVATION: #ff8256;
$PENDING_DECLARATION: #ff8256;
$ABORTED:#717171;

//Sidebar Color
$sidebar-minimizer-hover-bg:$primaryColor !important; //hover when point over a closed sidebar
$sidebar-minimizer-hover-indicator-color:white !default; //button color changed when point over a closed sidebar
$sidebar-minimizer-indicator-color: #4a4a4a !default; 

//Z-Index levels
$zIndex-god: 100;
$zIndex-sky: 20;
$zIndex-ground-3: 3;
$zIndex-ground-2: 2;
$zIndex-ground-1: 1;
$zIndex-ground-0: 0;

.default-all-color{
    color: $OPEN;
}

.open-color{
    color: $OPEN;
}

.pending-color{
    color: $PENDING;
}
.rejected-color{
    color: $REJECTED;
}

.completed-color{
    color: $COMPLETED;
}

.pending-activation-color{
    color: $PENDING_ACTIVATION;
}

.pending-declaration-color{
  color: $PENDING_DECLARATION;
}

.aborted-color{
  color: $ABORTED;
}

/* For printing content UI purpose */
@media print {

    [class*="col-sm-"] {
      float: left;
    }
  
    [class*="col-xs-"] {
      float: left;
    }
  
    .col-sm-12, .col-xs-12 {
      width:100% !important;
    }
  
    .col-sm-11, .col-xs-11 {
      width:91.66666667% !important;
    }
  
    .col-sm-10, .col-xs-10 {
      width:83.33333333% !important;
    }
  
    .col-sm-9, .col-xs-9 {
      width:75% !important;
    }
  
    .col-sm-8, .col-xs-8 {
      width:66.66666667% !important;
    }
  
    .col-sm-7, .col-xs-7 {
      width:58.33333333% !important;
    }
  
    .col-sm-6, .col-xs-6 {
      width:50% !important;
    }
  
    .col-sm-5, .col-xs-5 {
      width:41.66666667% !important;
    }
  
    .col-sm-4, .col-xs-4 {
      width:33.33333333% !important;
    }
  
    .col-sm-3, .col-xs-3 {
      width:25% !important;
    }
  
    .col-sm-2, .col-xs-2 {
      width:16.66666667% !important;
    }
  
    .col-sm-1, .col-xs-1 {
      width:8.33333333% !important;
    }
  
    .col-sm-1,
    .col-sm-2,
    .col-sm-3,
    .col-sm-4,
    .col-sm-5,
    .col-sm-6,
    .col-sm-7,
    .col-sm-8,
    .col-sm-9,
    .col-sm-10,
    .col-sm-11,
    .col-sm-12,
    .col-xs-1,
    .col-xs-2,
    .col-xs-3,
    .col-xs-4,
    .col-xs-5,
    .col-xs-6,
    .col-xs-7,
    .col-xs-8,
    .col-xs-9,
    .col-xs-10,
    .col-xs-11,
    .col-xs-12 {
    float: left !important;
    }

    html, body { height: auto; } //remove extra blank page in IE
  
    body {
      min-width: 768px;
      font-size: 10px;
    }
  
    .container {
      width: auto;
      min-width: 750px;
    }
  
    a[href]:after {
      content: none;
    }
  
    .readOnlyTextBox-transparent{
      height: 50px;
    }
   
  }