@import "../../scss/variables";

.nav{
    align-items: center;
}

.disabledFixedPosition{
    position: relative !important;
}

.logo{
    width: 30px;
    height: 30px;
}

.logoName{
    font-size: 16px;
    font-weight: bold;
    color: $darkBlue;
    margin-left: 10px;
}

.logoSubtitle{
    font-size: 16px;
    font-weight: 500;
    color: $darkBlue;
    margin-left: 15px;
    border-left: 1px solid $grey-400;
    padding-left: 15px;
}

.logoVersion{
    color: $grey-700;
    font-size: 14px;
    line-height: 1.15;
}

.logoTextContainer{
    display: flex;
    flex-direction: row; 
}

.userNameContainer{
    padding-left: 32px;
}

.userName{
    font-size: 14px;
    font-weight: 400;
    line-height: 1.15;
    text-align: end;
    color: $darkBlue;
    padding-bottom: 5px;
}

.userNameSubtitle{
    font-size: 8px;
    font-weight: 300;
    line-height: 1.15;
    color: $darkBlue;
}

.transparentBtn{
    background-color: transparent;
    border: none; 
    color: $grey-900;
    box-shadow: none;
    &:hover,&:focus{
        box-shadow: none;
        background-color: transparent;
    }
}

.logoutBtn{
    background-color: transparent;
    border: none; 
    box-shadow: none;
    &:hover,&:focus{
        box-shadow: none;
        background-color: transparent;
    }
}

.help {
    align-items: center;
    display: flex;
    font-size: 0.875rem;
    line-height: 1rem;
    letter-spacing: 0.15px;

    a {
        color: $darkBlue;
    }

    &::before {
        $help-size: 24px;
        align-items: center;
        border: 1px solid #ccc;
        border-radius: 50%;
        color: $darkBlue;
        content: "?";
        display: flex;
        font-size: 0.875rem;
        height: $help-size;
        justify-content: center;
        line-height: 0.5;
        margin-right: 3px;
        place-items: center;
        width: $help-size;
    }
}
