@import "../../scss/variables";

.checkbox-source-filtering-container {
    .error-filter-checkbox-btn{
        background-color: rgba(0,0,0,0);
        border: 1px solid $primaryColor;
        border-radius: 30px;
        font-size: 12px;
        margin: 5px 10px;
    }
    .error-filter-checkbox-btn:not(:disabled):not(.disabled).active{
        background-color: $primaryColor !important;
        color: $whiteColor !important;
    }
}