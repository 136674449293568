@import "../../../scss/variables";
.section {
  flex: 1;

  .filterBar {
    background: linear-gradient(-90deg, #4485a9 0%, #013b64 81.77%);
    color: $whiteColor;
    padding: 16px 32px;
    padding-right: 72px;
  }

  h1 {
    font-size: 1.3125rem;
    font-weight: 400;
    line-height: 1.5625rem;
    margin: 0;
  }

  .filters {
    display: flex;
    margin-top: 16px;

    > div {
      flex: 1;
    }
  }

  .filtersLeft {
    display: flex;

    form {
      flex: 1;
      margin-left: 8px;
      margin-top: 26px;
    }

    .position-relative.row.form-group {
      margin: 0;
    }
  }

  .dropdown {
    &.invalid {
      & .dropdownToggle {
        border-color: red;
      }
      & ~ :global(.invalid-feedback) {
        display: block;
      }
    }
  }

  .dropdownToggle {
    font-size: 0.875rem;
    height: 38px;
    line-height: 1rem;
    padding: 10px;
  }

  .secondaryFilters {
    display: flex;
    justify-content: flex-end;
    padding: 16px 32px;
    padding-right: 72px;
  }

  .filterByStatus {
    align-items: center;
    display: flex;

    label {
      margin: 0;
      margin-right: 10px;
    }
  }

  .exportCsv {
    margin-left: 20px;

    button {
      height: 38px;
    }
  }

  .customerList {
    padding: 16px 32px;
    padding-right: 72px;

    .nav {
      display: flex;
      justify-content: flex-end;
      margin: 32px 0;
    }

    .pagination {
      margin: 0;
    }
  }

  .tableContainer {
    border: 1px solid #c9ced3;
    border-radius: 4px;
    box-shadow: 0px 5px 10px rgba(170, 170, 170, 0.1);
    margin-bottom: 32px;
    overflow: auto;
  }

  .table {
    width: 100%;

    th,
    td {
      padding: 16px;
    }

    th {
      background-color: $whiteColor;
    }

    td {
      border-top: 1px solid #c9ced3;
    }

    tbody > tr {
      &:nth-child(even) > td {
        background-color: $whiteColor;
      }

      &:first-child > td {
        border-top-width: 2px;
      }
    }
  }

  a {
    color: $primaryColor;
  }

  .statusPill {
    border-radius: 4px;
    font-size: 0.75rem;
    font-weight: 500;
    line-height: 1.25rem;
    padding: 2px 7px;

    &.submitted {
      background-color: $primaryColor;
      color: $whiteColor;
    }

    &.approved {
      background-color: #27ae60;
      color: $whiteColor;
    }

    &.rejected {
      background-color: #d71920;
      color: $whiteColor;
    }

    &.processing {
      background-color: #f2c94c;
      color: #333333;
    }

    &.activated {
      background-color: #f16932;
      color: $whiteColor;
    }
  }

  .primaryOutlined {
    background-color: $whiteColor;
    border-color: $primaryColor;
    color: $primaryColor;
    transition-duration: 0.1s;
  }

  .pageItem.disabled {
    cursor: no-drop;

    button {
      color: #ccc !important;
    }
  }
  .backButton {
    align-items: center;
    cursor: pointer;
    display: inline-flex;
    margin-bottom: 8px;
    margin-left: -21px;
    margin-top: 0;

    &:hover {
      text-decoration: underline;
    }

    span {
      font-size: 1.125rem;
      font-weight: 700;
      line-height: 1.25rem;
      margin-left: 12px;
    }
  }
  .breadcrumbs {
    color: #888;
    font-size: 0.875rem;
    line-height: 1.25rem;
    margin-bottom: 32px;

    .highlighted {
      color: #63a5da;
      font-weight: 500;
    }
  }
  .userDetails {
    flex: 1;
    padding: 52px;
    padding-top: 36px;
  }
  .userDetailsForm {
    .userDetailsFormTitle {
      color: #d71920;
      font-weight: 700;
    }
    .userDetailsFormTitle1 {
      color: #002659;
      font-weight: 700;
    }

    .userDetailsFormDetails {
      display: flex;
      flex-direction: column;
      margin-top: 11px;
      width: 50%;
    }

    .inputField {
      margin-bottom: 16px;

      .caption {
        color: #666666;
        font-weight: 400;
      }
    }

    .unEditableField {
      font-weight: 700;
      height: 38px;
      display: flex;
      align-items: center;
    }

    .userDetailsFormAction {
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }

    .userDetailsDeleteButton {
      margin-right: 24px;
      padding: 0;
      background: none;
      border: none;
    }

    .userDetailsSubmitButton {
      background-color: $primaryColor;
      color: $whiteColor;
      width: 180px;
    }
  }
  .backButton {
    align-items: center;
    cursor: pointer;
    display: inline-flex;
    margin-bottom: 8px;
    margin-left: -21px;
    margin-top: 0;

    &:hover {
      text-decoration: underline;
    }

    span {
      font-size: 1.125rem;
      font-weight: 700;
      line-height: 1.25rem;
      margin-left: 12px;
    }
  }
  .breadcrumbs {
    color: #888;
    font-size: 0.875rem;
    line-height: 1.25rem;
    margin-bottom: 25px;

    .highlighted {
      color: #63a5da;
      font-weight: 500;
    }
  }
}

.modalHeader {
  background-color: #f4f4f4;
  padding: 1.25rem;

  button {
    font-size: 1.75rem;
    font-weight: 500;
    opacity: 0.75;
  }
}

.modalActions {
  display: flex;
  justify-content: flex-end;
  padding: 0px 4px 0px;
}

.modalButtonSecondary {
  background-color: #fff;
  border-color: black;
  width: 20%;
}

.modalButtonPrimary {
  margin-left: 16px;
  width: 20%;
  background-color: #002559;
  color: #fff;
}

.alink {
  cursor: pointer;
  text-decoration: underline;
  color: $primaryColor !important;

  &:hover {
    background: rgba(38, 111, 182, 0.5) !important;
  }

  &.active {
    background: rgba(38, 111, 182, 0.5) !important;
  }
}

// Toast Notification
.toastNotification {
  align-items: center;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  overflow: hidden;
  padding: 10px;
  position: fixed;
  right: 12px;
  top: 54px;
  width: 268px;
  z-index: 1000;
}

.toastContents {
  border-left: 1px solid #eee;
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  padding-left: 10px;

  span {
    font-size: 0.875rem;
    line-height: 1rem;
  }

  .toastTitle {
    font-weight: 700;
  }
}

.toastTickIcon,
.toastCloseIcon {
  flex-shrink: 0;
}

.toastCloseIcon {
  align-self: flex-start;
}

.toastDecoration {
  bottom: 0;
  display: flex;
  height: 3px;
  left: 0;
  position: absolute;
  right: 0;

  span:first-child {
    background-color: #27ae60;
    flex: 0 0 160px;
  }

  span:last-child {
    background-color: rgba(39, 174, 96, 0.25);
    flex-grow: 1;
  }
}

.toastDecorationWarning {
  bottom: 0;
  display: flex;
  height: 3px;
  left: 0;
  position: absolute;
  right: 0;

  span:first-child {
    background-color: #f2c94c;
    flex: 0 0 160px;
  }

  span:last-child {
    background-color: #f2c94c;
    opacity: 0.25;
    flex-grow: 1;
  }
}

// Alert
.alert{
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  padding: 6px;
  margin-bottom: 12px;
  border-left: 3px solid #fff;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.1);
  align-items: center;

  &.warn{
    border-color: #F2C94C;
  }
}
  .alertIcon{
    padding: 12px 12px 12px 6px;
    border-right: 1px solid #eee;
  }

  .alertMsg{
    padding: 12px;
    font-size: 14px;
    font-weight: 700;
    line-height: 16px;
    color: #333;
  }
