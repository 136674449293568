$verifyChecked: #4d9400;
$ErrorColor: #ee495d;

.test {
  color: green;
}

.kyi-input-wrapper {
  position: relative;
  display: inline-block;
}

.kyi-input-wrapper + .kyi-input-wrapper {
  padding-left: 10px;
}

.input {
    font-weight: 500 !important;
}

.form-tick {
  position: absolute;
  top: 45px;
  bottom: 0;
  right: 15px;
  height: 15px;
  width: 15px;
  font-size: 14px;
  color: $verifyChecked;
}

.nolabel-form-tick {
  position: absolute;
  top: 15px;
  bottom: 0;
  right: 15px;
  height: 15px;
  width: 15px;
  font-size: 14px;
  color: $verifyChecked;
}

.short-form-tick {
  position: absolute;
  top: 65px;
  bottom: 0;
  right: 15px;
  height: 15px;
  width: 15px;
  font-size: 14px;
  color: $verifyChecked;
}

.tips-msg {
  position: absolute;
  color: $ErrorColor;
  font-size: 12px;
  white-space: inherit;
  padding-right: 15px;
  text-align: justify;
  text-align-last: right;
  @media (max-width: 768px) {
    position: relative;
    bottom: inherit;
    margin: 0;
    text-align-last: left;
  }
}
.note-msg {
  position: absolute;
  font-size: 12px;
  text-align: justify;
  text-align-last: right;
  padding-right: 15px;
  white-space: inherit;
  @media (max-width: 768px) {
    position: relative;
    bottom: inherit;
    margin: 0;
    text-align-last: left;
  }
}