@import "../../scss/_variables";

.features-container{

    margin-left: 15px;

    @media print {
        color-adjust: exact !important; // to display checkbox at print
    }
    
    .custom-control-label{
        font-weight: bold ;
        color: black;
        margin-top: 5px;
    }

    .custom-checkbox .custom-control-input:checked ~ .custom-control-label::before{
        background-color: $primaryColor;
    }
}