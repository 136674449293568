.drop-down-wrapper {
  position: relative;
}

.form-check-dropdown {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  right: 55px;
  height: 15px;
  width: 15px;
  font-size: 14px;
  color: #4d9400;
}

.dropdrop-label {
  width: 100%;
  display: none !important;
  position: absolute;
  top: -20px;
  letter-spacing: 0.5px;
  font-weight: 500;
  font-size: 10px;
  color: black;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  &.show {
    display: block !important;
  }
}
