.default-all-color {
  color: #000000; }

.open-color {
  color: #000000; }

.pending-color {
  color: #ff8256; }

.rejected-color {
  color: #d0021b; }

.completed-color {
  color: #417505; }

.pending-activation-color {
  color: #ff8256; }

.pending-declaration-color {
  color: #ff8256; }

.aborted-color {
  color: #717171; }

/* For printing content UI purpose */
@media print {
  [class*="col-sm-"] {
    float: left; }
  [class*="col-xs-"] {
    float: left; }
  .col-sm-12, .col-xs-12 {
    width: 100% !important; }
  .col-sm-11, .col-xs-11 {
    width: 91.66666667% !important; }
  .col-sm-10, .col-xs-10 {
    width: 83.33333333% !important; }
  .col-sm-9, .col-xs-9 {
    width: 75% !important; }
  .col-sm-8, .col-xs-8 {
    width: 66.66666667% !important; }
  .col-sm-7, .col-xs-7 {
    width: 58.33333333% !important; }
  .col-sm-6, .col-xs-6 {
    width: 50% !important; }
  .col-sm-5, .col-xs-5 {
    width: 41.66666667% !important; }
  .col-sm-4, .col-xs-4 {
    width: 33.33333333% !important; }
  .col-sm-3, .col-xs-3 {
    width: 25% !important; }
  .col-sm-2, .col-xs-2 {
    width: 16.66666667% !important; }
  .col-sm-1, .col-xs-1 {
    width: 8.33333333% !important; }
  .col-sm-1,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12,
  .col-xs-1,
  .col-xs-2,
  .col-xs-3,
  .col-xs-4,
  .col-xs-5,
  .col-xs-6,
  .col-xs-7,
  .col-xs-8,
  .col-xs-9,
  .col-xs-10,
  .col-xs-11,
  .col-xs-12 {
    float: left !important; }
  html, body {
    height: auto; }
  body {
    min-width: 768px;
    font-size: 10px; }
  .container {
    width: auto;
    min-width: 750px; }
  a[href]:after {
    content: none; }
  .readOnlyTextBox-transparent {
    height: 50px; } }

.main {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #fafafa; }

.section {
  background-color: #fafafa;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 60px; }

.banner {
  position: relative;
  width: 100%;
  height: 100px;
  overflow: hidden;
  display: flex;
  align-items: center; }

.bannerBg {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-position: left top;
  background-repeat: no-repeat;
  background-size: cover; }

.bannerContent {
  position: relative;
  z-index: 2;
  color: #ffffff;
  font-size: 32px;
  font-weight: 400;
  line-height: 1.15; }
  .bannerContent p {
    margin-bottom: 0; }
    .bannerContent p > span {
      font-size: 26px;
      font-weight: 300;
      padding-left: 8px; }

.form {
  position: relative;
  display: flex;
  flex: 1;
  background-color: #fafafa;
  justify-content: center;
  align-items: center;
  padding: 16px;
  color: #333; }

.formCard {
  padding: 32px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.2); }

.formCardHeader {
  padding-bottom: 48px; }
  .formCardHeader > h1 {
    font-size: 32px;
    font-weight: 400;
    line-height: 1;
    letter-spacing: .5px;
    margin-bottom: 10px; }
  .formCardHeader > p {
    font-size: 14px;
    font-weight: 400;
    line-height: 1.15;
    letter-spacing: .3px;
    margin-bottom: 0;
    color: #494949; }
  .formCardHeader.lessPB {
    padding-bottom: 32px; }

.inputGroup {
  display: flex;
  flex-direction: column-reverse;
  padding-bottom: 20px; }
  .inputGroup.fdRow {
    flex-direction: row; }
  .inputGroup.lessPB {
    padding-bottom: 12px; }
  .inputGroup label {
    display: block;
    width: 100%;
    color: #333333;
    font-size: 12px;
    font-weight: 400;
    line-height: 1.15;
    margin-bottom: 0; }
  .inputGroup input[type=text],
  .inputGroup input[type=password] {
    width: 350px !important;
    border: none;
    border-radius: 0;
    border-bottom: 1px solid #D9D9D9;
    padding: 5px 0 8px;
    font-size: 14px;
    line-height: 1.15;
    height: auto;
    color: #333333; }
    .inputGroup input[type=text]:focus,
    .inputGroup input[type=password]:focus {
      box-shadow: none;
      border-bottom-color: #4A90E2; }
      .inputGroup input[type=text]:focus + label,
      .inputGroup input[type=password]:focus + label {
        color: #4A90E2; }
    .inputGroup input[type=text].error,
    .inputGroup input[type=password].error {
      border-bottom-color: #d0021b; }
      .inputGroup input[type=text].error + label,
      .inputGroup input[type=password].error + label {
        color: #d0021b; }
  .inputGroup label.radioBlock {
    border: 1px solid #d0cecf;
    border-radius: 8px;
    padding: 16px;
    min-width: 120px;
    text-align: center;
    font-weight: 700; }
    .inputGroup label.radioBlock input[type=radio] {
      display: none;
      visibility: hidden; }
    .inputGroup label.radioBlock.active {
      border-color: #323c6b;
      background-color: #e2ecf3; }
    .inputGroup label.radioBlock > img {
      display: block;
      margin: 16px auto; }
    .inputGroup label.radioBlock:not(:first-child) {
      margin-left: 24px; }

.formMessage {
  font-size: 14px;
  font-weight: 400;
  max-width: 25em;
  color: #333333;
  padding-bottom: 32px; }

.formCTA {
  width: 165px;
  padding-top: 16px; }
  .formCTA > button {
    width: 100%;
    padding: 12px;
    background: linear-gradient(93.71deg, #002559 0%, #5785C6 100%);
    border: none;
    border-radius: 4px;
    color: #ffffff;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.15;
    letter-spacing: .3px; }
    .formCTA > button:hover {
      opacity: 0.8; }
    .formCTA > button:focus {
      outline: none; }
    .formCTA > button:disabled {
      background: #d0cecf; }
    .formCTA > button.flushBg {
      background: transparent;
      color: #000; }
  .formCTA > a {
    display: block;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.15;
    color: #002559;
    padding-top: 10px;
    outline: none; }
  .formCTA.twoCol {
    width: 100%; }
    .formCTA.twoCol > button {
      width: calc(50% - 4px);
      display: inline-block;
      vertical-align: middle; }

.resendCta {
  color: #20a8d8 !important;
  cursor: pointer; }
  .resendCta.disabled {
    color: #d0cecf !important;
    cursor: default;
    pointer-events: none; }
