@import "../../../scss/variables";

.main{
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: $grey-100;
}

.section{
  background-color: $grey-100;
  flex:1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 60px;
}

.banner{
  position: relative;
  width: 100%;
  height: 100px;
  overflow: hidden;
  display: flex;
  align-items: center;
}
  .bannerBg{
    position: absolute;
    top: 0;
    left: 0;
    z-index: $zIndex-ground-1;
    width: 100%;
    height: 100%;
    background-position: left top;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .bannerContent{
    position: relative;
    z-index: $zIndex-ground-2;
    color: $white;
    font-size: 32px;
    font-weight: 400;
    line-height: 1.15;
    p{
      margin-bottom: 0;
      &>span {
        font-size: 26px;
        font-weight: 300;
        padding-left: 8px;
      }
    }
  }

.form{
  position: relative;
  display: flex;
  flex: 1;
  background-color: $grey-100;
  justify-content: center;
  align-items: center;
  padding: 16px;
  color: #333;
}
  .formCard{
    padding: 32px;
    background-color: $white;
    border-radius: 8px;
    box-shadow: 0 2px 3px rgba(0,0,0,0.2);
  }
    .formCardHeader{
      padding-bottom: 48px;
      &>h1{
        font-size: 32px;
        font-weight: 400;
        line-height: 1;
        letter-spacing: .5px;
        margin-bottom: 10px;
      }
      &>p{
        font-size: 14px;
        font-weight: 400;
        line-height: 1.15;
        letter-spacing: .3px;
        margin-bottom: 0;
        color: $grey-800;
      }
      &.lessPB{
        padding-bottom: 32px;
      }
    }
    .inputGroup{
      display: flex;
      flex-direction: column-reverse;
      padding-bottom: 20px;
      &.fdRow{
        flex-direction: row;
      }
      &.lessPB{
        padding-bottom: 12px;
      }
      label{
        display: block;
        width: 100%;
        color: $grey-900;
        font-size: 12px;
        font-weight: 400;
        line-height: 1.15;
        margin-bottom: 0;
      }
      input[type=text],
      input[type=password]{
        width: 350px !important;
        border: none;
        border-radius: 0;
        border-bottom: 1px solid $grey-300;
        padding: 5px 0 8px;
        font-size: 14px;
        line-height: 1.15;
        height: auto;
        color: $grey-900;
        &:focus{
          box-shadow: none;
          border-bottom-color: $lightBlue;
          &+label{
            color: $lightBlue;
          }
        }
        &.error{
          border-bottom-color: $REJECTED;
          &+label{
            color: $REJECTED;
          }
        }
      }
      label.radioBlock{
        border: 1px solid #d0cecf;
        border-radius: 8px;
        padding: 16px;
        min-width: 120px;
        text-align: center;
        font-weight: 700;

        input[type=radio]{
          display: none;
          visibility: hidden;
        }

        &.active{
          border-color: #323c6b;
          background-color: #e2ecf3;
        }

        &>img{
          display: block;
          margin: 16px auto;
        }

        &:not(:first-child){
          margin-left: 24px;
        }
      }
    }
    .formMessage{
      font-size: 14px;
      font-weight: 400;
      max-width: 25em;
      color: #333333;
      padding-bottom: 32px;
    }
    .formCTA{
      width: 165px;
      padding-top: 16px;
      &>button{
        width: 100%;
        padding: 12px;
        background: linear-gradient(93.71deg, #002559 0%, #5785C6 100%);
        border: none;
        border-radius: 4px;
        color: $white;
        font-size: 14px;
        font-weight: 400;
        line-height: 1.15;
        letter-spacing: .3px;
        &:hover{
          opacity: 0.8;
        }
        &:focus{
          outline: none;
        }
        &:disabled{
          background: #d0cecf;
        }
        &.flushBg{
          background: transparent;
          color: #000;
        }
      }
      &>a{
        display: block;
        font-size: 14px;
        font-weight: 400;
        line-height: 1.15;
        color: $darkBlue;
        padding-top: 10px;
        outline: none;
      }

      &.twoCol{
        width: 100%;
        &>button {
          width: calc(50% - 4px);
          display: inline-block;
          vertical-align: middle;
        }
      }
    }
    .resendCta{
      color: #20a8d8 !important;
      cursor: pointer;
      &.disabled{
        color: #d0cecf !important;
        cursor: default;
        pointer-events: none;
      }
    }
