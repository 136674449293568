@import "../../scss/variables";

.list-item{
    border-radius: 15px;
    border: 1px solid #ccc;
    background-color: $whiteColor;
    margin: 5px;
}

.list-item-container{
    padding: 0px 10px;
    margin: 5px;
    margin-left: 25px;

    .title-front{
        font-size: 1.3125rem;
        font-weight: bold;
        color: red;
    }

    .title-wrap{
        font-size: 1.3125rem;
        font-weight: bold;
        color: #002559;
    }
    
    .left-item-wrap{
        border-right: 1px solid $lightGreyColor;
    }
    
    .right-item-wrap{
        align-items : center;
    }
    
    .custom-secondary-btn{
        background-color: $primaryColor;
        color: $primaryColor;
        font-weight: 600;
        padding:5px;
        background-color: transparent;
        border-color: transparent;
    }
}

.collapse-item-container{
    
    .default-collapse-item{
        padding: 10px;
        border-top: 1px solid #ccc;
        margin: 5px;
    }
}