body {
    font-family: Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.header-fixed .app-body {
    margin-top: 45px;
}

.topNav {
    height: 45px;
}