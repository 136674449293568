@import "../../scss/variables";

.fileInput{
  width: 100%;
  &.invalid-- .dropZone{
    border-color: $REJECTED;
  }
}
.dropZone{
  position: relative;
  width: 100%;
  min-height: 164px;
  padding: 20px;
  background-color: #F9FAFB;
  border: 2px dashed #C4CDD5;
  border-radius: 3px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &.active--{
    border-color: #858686;
  }
}

.dropZone-action{
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
  left: 0;
  top: 0;
}

.dropZone-content{
  position: relative;
  z-index: 1;
  font-size: 16px;
  font-weight: 400;
  color: #969797;
  line-height: 1.31;
  text-align: center;
  & > svg {
    width: 100%;
    max-width: 102px;
  }
  & > p {
    max-width: 14em;
    padding-top: 12px;
    & > span {
      display: inline;
      cursor: pointer;
      color: #4b74ff !important;
      text-decoration: underline !important;

      &:hover{
        opacity: 0.8;
      }
    }
  }
}

.dropZone-fileInput{
  display: none;
}

.dropZone-errorMsg{
  padding-top: 8px;
  font-size: 12px;
  font-size: 400;
  line-height: 1.15;
  color: $REJECTED;
}