.readOnlyTextBox-label{
        margin-left: 10px;
        font-size: 15px;
        margin-bottom: 0px;
        color: grey;
}

.readOnlyTextBox-form{
    background-color: #f5f5f5;
    height: 78px;
    margin-top: 10px;
}

.readOnlyTextBox-transparent{
    background-color: transparent;
    height: 78px;
    margin-top: 10px;
    border-color: transparent;
}

.readOnlyTextBox-input{
    margin-left: 10px;
    height: 40px;
    padding-top: 10px; 
    font-weight: bold;
}
