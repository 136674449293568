@import "../../../../../../scss/variables";

$width: 375px;
$baseRatio: 375px;
$primaryColor: #002659;
$secondaryColor: #D71920;
$tertiaryColor: #D71920;

// general
.sdkPreview{
  position: relative;
  padding: 0 18px;
}
.main{
  position: relative;
  z-index: 1;
  width: $width;
  margin: auto;
  padding: 16px 0;
  background-color: #fafafa;
}
.frame{
  position: absolute;
  z-index: 2;
  left: -18px;
  top: 0;
  width: 410px;
  &>svg{
    width: 410px;
  }
}
.wrapper{
  position: relative;
  width: $width;
  height: 810px;
}
.container{
  margin: 0 24px;
}
// .preHeader{
//   background-color: #3333;
//   padding-top: 32px;
//   background-color: $primaryColor;
//   border-top-left-radius: 10px;
//   border-top-right-radius: 10px;
// }
// Header
.header{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 48px 16px 16px;
  background-color: $primaryColor;
  border-bottom: 4px solid $tertiaryColor;
}
  .headerTitle{
    color: #fff;
    font-size: (17px/$baseRatio*$width);
  }
  .headerBack{
    fill: #fff;
    width: (24px/$baseRatio*$width);
    height: (24px/$baseRatio*$width);
  }
  .headerClose{
    fill: #fff;
    width: (24px/$baseRatio*$width);
    height: (24px/$baseRatio*$width);
  }
// Steps
.stepSection{
  padding: 10px 24px;
  background-color: #F2F8FF;
  box-shadow: 0px 2px 5px 0 rgba(204,204,204,0.8);
  display: flex;
  justify-content: space-between;
  align-items: center;
}
  .stepInfo{
    position: relative;
  }
    .stepInfoTitle{
      font-size: (16px/$baseRatio*$width);
      font-weight: 700;
      line-height: 1.125;
      color: #333;
      text-align: right;
      padding-bottom: 3px;
    }
    .stepInfoNext{
      font-size: (12px/$baseRatio*$width);
      font-weight: 400;
      line-height: 1.125;
      color: #333;
      text-align: right;
    }
// ProgressBar
.progressBar{
  position: relative;
  overflow: hidden;
  width: 50px;
  height: 50px;
  border-radius: 25px;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  background-color: #D8D8D8;
}
  .progressBarInner{
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 42px;
    height: 42px;
    border-radius: 21px;
    background-color: #F2F8FF;
    z-index: 2;
  }
    .progressBarInnerStep{
      color: #666;
      line-height: 1.2;
      font-size: (8px/$baseRatio*$width);
    }
    .progressBarInnerCounter{
      color: #333;
      font-size: (10px/$baseRatio*$width);
      line-height: 1.1;
      font-weight: 700;
    }
  .progressBarLeftPanel{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding-right: 50%;
    z-index: 1;
    transform: rotate(298deg);
  }
  .progressBarRightPanel{
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    padding-left: 50%;
    z-index: 1;
  }
  .progressBarPanelInner{
    position: relative;
    width: 100%;
    height: 100%;
    background-color: $primaryColor;
  }
// Logo
.logoSection{
  padding: 16px 0;
}
  .logoWrapper{
    color: #666;
    font-size: (10px/$baseRatio*$width);
    line-height: 1.15;
    &>img{
      display: inline;
      vertical-align: middle;
      width: 100%;
      max-width: 64px;
      margin-left: 5px;
    }
  }
// Accordion
.accorSection{
  position: relative;
}
  .accorHeader{
    padding: 12px 32px;
    background-color: #EAECF3;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    min-height: 22px;
  }
    .accorHeaderTitle{
      position: relative;
      color: #333;
      font-size: (15px/$baseRatio*$width);
      font-weight: 700;
      line-height: 1.15;
      padding-left: 34px;
      padding-top: 4px;
    }
      .accorHeaderTitleIcon{
        position: absolute;
        left: 0;
        top: 0;
        & > svg{
          width: 24px;
        }
      }
    .accorHeaderArrow{
      width: 24px;
      fill: #6B6C6F;
      &.rotated{
        transform: rotate(180deg);
      }
    }
  .accorBody{
    position: relative;
    padding: 24px 62px 0 32px;
  }
    .accorBodyClose{
      position: absolute;
      top: 24px;
      right: 32px;
      & > svg {
        width: 18px;
        height: 18px;
      }
    }
// Input
.inputBlock{
  padding-bottom: 24px;
}
  .inputLabelBold{
    color: #333;
    font-size: (14px/$baseRatio*$width);
    font-weight: 700;
    padding-bottom: 16px;
    line-height: 1;
  }
    .inputLabelTooltip{
      display: inline;
      padding-left: 8px;
    }
// CheckboxBlock
.checkboxBlock{
  display: flex;
  flex-direction: row;
  align-items: center;
}
  .checkbox{
    padding: 9px 9px 9px 0;
    margin-left: -3px;
    & > svg {
      width: 24px;
      height: 24px;
    }
  }
  .checkboxLabel{
    font-size: (14px/$baseRatio*$width);
  }
// SwitchBlock
.switchBlock{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
  .switchButton{
    padding: 12px 16px;
    border: 1px solid #BDBDBD;
    border-radius: 8px;
    font-size: (14px/$baseRatio*$width);
    font-weight: 500;
    line-height: 1.75;
    text-align: center;
    height: 48px;
    width: calc(50% - 8px);
    background-color: #fff;
  }
//butttonBlock
.buttonBlock{
  width: 100%;
  padding: 24px;
}
  .priButton,
  .disabledButton{
    padding: 12px 16px;
    font-size: (14px/$baseRatio*$width);
    font-weight: 500;
    line-height: 1.75;
    color: #333;
    box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
    text-align: center;
    border-radius: 8px;
    background-color: $primaryColor;
  }
  .disabledButton{
    background-color: #e0e0e0;
    color: #a6a6a6;
    box-shadow: none;
  }
// Submit
.submitSection{
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 8px;
  padding: 0 24px;
}