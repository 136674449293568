@import "../../scss/variables";

.font {
    font-size: 0.75rem;
    line-height: 0.875rem;
}

.dropdownToggle,
.dropdown-toggle {
    background-color: $white;
    width: 100%;
    &:hover {
        background-color: $grey-100;
    }
    &:active {
        background-color: $grey-100;
    }
    &:disabled{
        cursor: not-allowed;
        &:hover {
            background-color: #c8ced3;
        }
    }
}

.dropdown>button {
    align-items: center;
    display: flex;
    justify-content: space-between;
    min-width: 184px;
}

.dropdown-menu {
    width: 100%;
    &.show{
        box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.06);
    }
}

.dropdown-item {
    outline: none !important;

    &:active {
        background-color: $darkBlue !important;
    }
}

.scrollable-menu {
    height: auto;
    max-height: 400px;
    overflow-x: hidden;
}

.btn-secondary:not(:disabled):not(.disabled):active, 
.btn-secondary:not(:disabled):not(.disabled).active, 
.show > .btn-secondary.dropdown-toggle{
    background-color: $white;
    &:hover {
        background-color: $grey-100;
    }
    &:active {
        background-color: $grey-100;
    }
}
