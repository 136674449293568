@import "../../scss/_variables";

.eddBadge-container{
    padding: 4px;
}

.eddBadge-body{
    background-color: $whiteColor;
    border: 1px solid $dbosGrey;
}

.eddBadge-icon{
    margin-right: 4px; 
    color:$primaryColor; 
    font-size: 20px; 
}

.eddBadge-text{
    font-size: 14px;
}