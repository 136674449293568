@import "../../scss/variables";


.underline-button
{
    .wrap{
        display : flex;
        align-items : center;
        border-right: 1px solid $lightGreyColor;
    }
    
    &.btn-secondary {
        width:115px;
        background-color: $greyWhiteColor;
        margin-left: 5px;
        margin-right: 5px;
        margin:0 ;
        border-bottom: 3px solid $whiteColor;
        font-weight: 600;
        font-size: 12px;
        border-radius: 0 ;
        padding: 8px ;
        color: black ;
    }
    
    &.btn-secondary:not(:disabled):not(.disabled):active, 
    &.btn-secondary:not(:disabled):not(.disabled).active {
        background-color: $greyWhiteColor ;
        color: black ;
        font-weight: 600;
        border-bottom: 3px solid $secondaryColor ;
    }
}
