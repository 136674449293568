@import "../../scss/variables";

.react-datepicker {
    border: none;
    border-radius: 8px;
    box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: column;
    font-family: inherit;

    .react-datepicker__triangle {
        display: none !important;
    }

    .react-datepicker__header {
        background-color: $whiteColor;
        border-bottom: 1px dashed #ecebed;
        border-radius: 0;
    }

    .react-datepicker__navigation {
        border: none;
        border-radius: 6px;
        box-shadow: 0px 7px 20px rgba(0, 0, 0, 0.07);
        height: 24px;
        width: 24px;
        top: 8px;
    }

    .react-datepicker__navigation--previous {
        left: 16px;

        &::before {
            border-color: #333;
            border-style: solid;
            border-width: 2px 0 0 2px;
            content: "";
            display: block;
            height: 8px;
            margin: 8px;
            transform: rotateZ(315deg) translate(1px, 1px);
            width: 8px;
        }
    }

    .react-datepicker__navigation--next {
        right: 16px;

        &::before {
            border-color: #333;
            border-style: solid;
            border-width: 2px 0 0 2px;
            content: "";
            display: block;
            height: 8px;
            margin: 8px;
            transform: rotateZ(135deg) translate(1px, 1px);
            width: 8px;
        }
    }

    .react-datepicker__month-container {
        padding: 0 16px;
    }

    .react-datepicker__current-month {
        font-size: 1rem;
        font-weight: 400;
        line-height: 24px;
        text-transform: uppercase;
    }

    .react-datepicker__month {
        margin: 0;
    }

    .react-datepicker__day-names {
        margin-top: 8px;
    }

    .react-datepicker__day-name {
        color: #adabad;
    }

    .react-datepicker__day-name,
    .react-datepicker__day,
    .react-datepicker__time-name {
        $day-size: 36px;
        align-items: center;
        border-radius: 50%;
        display: inline-flex;
        font-size: 0.875rem;
        height: $day-size;
        justify-content: center;
        margin: 0;
        width: $day-size;
    }

    .react-datepicker__day--selected,
    .react-datepicker__day--in-selecting-range,
    .react-datepicker__day--in-range {
        background-color: $primaryColor;
    }

    .react-datepicker__day--today {
        font-weight: 400;
    }

    .react-datepicker__day--keyboard-selected {
        background-color: transparent;
        color: #000;

        &:hover {
            background-color: #f0f0f0;
        }
    }
}

.react-datepicker__input-container {
    display: block;
    width: 100%;
}

.react-datepicker-popper {
    margin-left: -37px;
    z-index: 10;
}

.filter-date-range-container {
    padding: 16px;
}

.filter-dates {
    margin-top: 8px;

    button,
    button:hover,
    button:focus,
    button:active {
        background-color: $whiteColor;
        border: 1px solid $primaryColor;

        +button {
            margin-left: 10px;
        }

        &.active {
            background-color: $primaryColor !important;
            border: 1px solid $primaryColor !important;
            color: $whiteColor !important;
        }
    }
}

.filter-date-range {
    border-left: 1px solid $whiteColor;
    display: flex;
    margin-left: 40px;
    padding-left: 40px;
}

.filter-date-label {
    flex: 0;
    font-size: 0.875rem;
    line-height: 1rem;
    margin-bottom: 16px;
    margin-top: 32px;
    text-align: center;
}

.filter-date-cal {
    flex: 1;
    margin-left: 32px;

    label {
        line-height: 1rem;
    }
}

.clear-button {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: flex-end;
    margin: 0 16px;

    button,
    button:hover,
    button:focus,
    button:active {
        background-color: transparent !important;
        border: none !important;
        color: $primaryColor;
        font-size: 1rem;
        font-weight: 500;
        line-height: 1.1875rem;
        margin-right: -16px;
        padding: 16px;
    }

    button:hover {
        text-decoration: underline;
    }
}

.filter-date-cal.to .react-datepicker-popper {
    left: auto !important;
    right: 37px;
}