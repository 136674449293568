//-----------------------------------------------------------------------------
//  Mixins
//-----------------------------------------------------------------------------
@mixin screen-xxs-down {
  @media (max-width: 374px) { @content; }
}

@mixin screen-xxs-up {
  @media (min-width: 375px) { @content; }
}

@mixin screen-xs-up {
  @media (min-width: 480px) { @content; }
}

@mixin screen-xs-down {
  @media (max-width: 479px) { @content; }
}

@mixin screen-sm-down {
  @media (max-width: 639px) { @content; }
}

@mixin screen-sm-up {
@media (min-width: 640px) { @content; }
}

@mixin screen-md-down {
  @media (max-width: 799px) { @content; }
}

@mixin screen-md-up {
  @media (min-width: 800px) { @content; }
}

@mixin screen-lg-up {
  @media (min-width: 1024px) { @content; }
}

@mixin screen-lg-down {
  @media (max-width: 1023px) { @content; }
}

@mixin screen-xl-down {
  @media (max-width: 1179px) { @content; }
}

@mixin screen-xl-up {
  @media (min-width: 1180px) { @content; }
}

@mixin screen-xxl-up {
  @media (min-width: 1600px) { @content; }
}
