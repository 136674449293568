@import "../../scss/variables";

.flex-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: white;
    border-radius: 5px;
}

.flex-container-fastCash {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: white;
    border-radius: 5px;
    border: 1px solid #C9CED3;

    input {
        border-bottom: 0;
        border-top: 0;
        border-right: 0;
    }
}

.icon-container {
    flex: 0 0 auto;
    padding: 0 12px;
}

.calender-icon {
    color: $primaryColor;
}

.date-picker-container {
    flex: 1 1 auto;
}

.react-datepicker-wrapper {
    display: flex;

    input {
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
    }
}