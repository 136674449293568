@import "../../scss/variables";

.popperToggle{
    border: 1px solid #e4e7ea;
    background-color: white;
    width: 100%;
    height: 100%;
    padding: 8px;
    padding-right: 20px;
    border-radius: 8px;
    text-overflow: ellipsis;
    white-space: nowrap; 
    overflow: hidden 
}

.content{
    font-size: 15px;
    background-color: white;
    border: 1px solid #e4e7ea;
    border-radius: 5px;
}

.caret{
   float: right;
   position:absolute;
   right: 25px
}
