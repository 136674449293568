@import "../../scss/variables";

.font{
    font-size: 12px;
}

.applicant-selector-dropdown-toggle{
    background-color: $primaryColor !important;
    width: 100%;
    color:white !important;
    padding: 10px 15px;
}

.applicant-dropdown-container{
    position: fixed; 
    right: 0px; 
    top: 10%;
    margin: 15px;
    z-Index: 999;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.applicant-selector-item:hover{
    background-color: $primaryColor;
    color: white;
}

